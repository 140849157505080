exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-news-events-events-category-wp-event-type-slug-js": () => import("./../../../src/pages/company/news-events/events/category/{WpEventType.slug}.js" /* webpackChunkName: "component---src-pages-company-news-events-events-category-wp-event-type-slug-js" */),
  "component---src-pages-company-news-events-news-category-wp-news-category-slug-js": () => import("./../../../src/pages/company/news-events/news/category/{WpNewsCategory.slug}.js" /* webpackChunkName: "component---src-pages-company-news-events-news-category-wp-news-category-slug-js" */),
  "component---src-pages-company-news-events-news-type-wp-news-type-slug-js": () => import("./../../../src/pages/company/news-events/news/type/{WpNewsType.slug}.js" /* webpackChunkName: "component---src-pages-company-news-events-news-type-wp-news-type-slug-js" */),
  "component---src-templates-article-index-js": () => import("./../../../src/templates/Article/index.js" /* webpackChunkName: "component---src-templates-article-index-js" */),
  "component---src-templates-configurator-js": () => import("./../../../src/templates/Configurator.js" /* webpackChunkName: "component---src-templates-configurator-js" */),
  "component---src-templates-event-index-js": () => import("./../../../src/templates/Event/index.js" /* webpackChunkName: "component---src-templates-event-index-js" */),
  "component---src-templates-news-article-index-js": () => import("./../../../src/templates/NewsArticle/index.js" /* webpackChunkName: "component---src-templates-news-article-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/Page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-product-category-index-js": () => import("./../../../src/templates/ProductCategory/index.js" /* webpackChunkName: "component---src-templates-product-category-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/Product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "slice---src-query-blocks-footer-block-js": () => import("./../../../src/queryBlocks/FooterBlock.js" /* webpackChunkName: "slice---src-query-blocks-footer-block-js" */),
  "slice---src-query-blocks-header-block-js": () => import("./../../../src/queryBlocks/HeaderBlock.js" /* webpackChunkName: "slice---src-query-blocks-header-block-js" */)
}

